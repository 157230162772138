import React, { useCallback } from "react";
import styled from "@emotion/styled/macro";
import { Link } from "react-router-dom";

import { useNavStore } from "../stores/hooks";

const Blind = styled.div`
  overflow: hidden;
  position: absolute;
  left: -9999em;
  width: 1px;
  height: 1px;
  margin: 0;
  padding: 0;
  background: none;
  text-indent: -9999em;
`;

const NavbarContainer = styled.div`
  width: 100%;
  max-width: 799px;
  margin: 0 auto;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  flex-direction: column;
`;

const NavbarHamberger = styled.button`
  background-color: transparent;
  background-image: url(/assets/images/hambug.png);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  width: 40px;
  height: 40px;
  z-index: 10;
  cursor: pointer;
`;

const NavbarBody = styled.div`
  display: none;
  flex-direction: column;
  justify-content: space-between;
`;

const Navbar = styled.div`
  ${({ navOpen }) =>
    navOpen &&
    `
      background-color: #202020;
      position: fixed;
      width: 100%;
      height: 100vh;
      z-index: 100;

      ${NavbarHamberger} {
        background-image: url(/assets/images/hambug-close.png);
      }

      ${NavbarContainer} {
        height: 100vh;
      }

      ${NavbarBody} {
        display: flex;
        flex: 1;
      }
    `};
`;

const NavbarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-item: center;
  padding: 16px 0;
`;
const NavbarLogo = styled.div`
  cursor: pointer;
  padding: 0;
  position: relative;
  font-size: 0;
`;
const NavbarLogoImg = styled.img`
  height: 39px;
`;

const NavbarGnb = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;
const NavbarGnbItem = styled.li`
  font-size: 16px;
  padding: 8px 0;
`;
const NavbarCopyright = styled.div`
  padding: 8px 0 24px;
  font-size: 12px;
  color: #999;
  border-top: 1px solid #333;
`;

function Nav() {
  const { navActive } = useNavStore().state;
  const { ...actions } = useNavStore();

  const handleClickNav = useCallback(() => {
    actions.toggleNav();
  }, [actions.toggleNav]);

  return (
    <Navbar navOpen={navActive}>
      <NavbarContainer>
        <NavbarHeader>
          <NavbarLogo>
            <Link to="/">
              <NavbarLogoImg
                src="/assets/images/logo.png"
                alt="두발히어로 로고"
              />
            </Link>
          </NavbarLogo>
          <NavbarHamberger type="button" onClick={handleClickNav}>
            <Blind>메뉴 열기 버튼</Blind>
          </NavbarHamberger>
        </NavbarHeader>
        <NavbarBody>
          <NavbarGnb>
            <NavbarGnbItem>
              <Link to={{ pathname: "http://dooshop.kr" }} target="_blank">
                당일배송 브랜드 상품 확인! 
              </Link>
            </NavbarGnbItem>
            <NavbarGnbItem>
              <Link
                to={{ pathname: "https://www.doobalhero.kr/" }}
                target="_blank"
              >
                두발히어로 홈페이지 
              </Link>
            </NavbarGnbItem>
            <NavbarGnbItem>
              <Link
                to={{ pathname: "https://voc.doobalhero.kr/" }}
                target="_blank"
              >
                두발히어로 배송 리뷰 
              </Link>
            </NavbarGnbItem>
            <NavbarGnbItem>
              <Link
                to={{ pathname: "https://check.doobalhero.kr/" }}
                target="_blank"
              >
                배송조회 
              </Link>
            </NavbarGnbItem>
            <NavbarGnbItem>
              <Link
                to={{ pathname: "https://return.doobalhero.kr/" }}
                target="_blank"
              >
                반품신청 
              </Link>
            </NavbarGnbItem>
            <NavbarGnbItem>
              <Link
                to={{ pathname: "https://www.doobalhero.kr/" }}
                target="_blank"
              >
                배송 계약 문의 
              </Link>
            </NavbarGnbItem>
            <NavbarGnbItem>
              <Link
                to={{ pathname: "https://rider.doobalhero.kr/" }}
                target="_blank"
              >
                배송기사 계약 문의 
              </Link>
            </NavbarGnbItem>
          </NavbarGnb>
          <NavbarCopyright>
            두발히어로TM
            <br />
            Copyright © 2018 ㈜체인로지스 All rights reserved.
          </NavbarCopyright>
        </NavbarBody>
      </NavbarContainer>
    </Navbar>
  );
}

export default Nav;
